import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PasswordRecoveryPhone from './components/PasswordRecoveryPhone';
import PasswordRecoveryCode from './components/PasswordRecoveryCode';
import PasswordRecoveryNewPassword from './components/PasswordRecoveryNewPassword';
import RegistrationPassport from '../RecoveryFormPassport/components/RegistrationPassport';
import RegistrationPhone from '../RecoveryFormPassport/components/RegistrationPhone';
import RegistrationCode from '../RecoveryFormPassport/components/RegistrationCode';
import RegistrationNewPassword from '../RecoveryFormPassport/components/RegistrationNewPassword';

import {
  resetRecovery,
} from '../../../../services/recovery/actions';

import { screenStates } from '../../../../services/recovery/constants';

class PasswordRecoveryForm extends Component {
  recoveryList = {
    [screenStates.GET_PHONE]: <PasswordRecoveryPhone />,
    [screenStates.CODE_APPROVE]: <PasswordRecoveryCode />,
    [screenStates.CREATE_NEW_PASSWORD]: <PasswordRecoveryNewPassword />,
    [screenStates.PASSPORT]: <RegistrationPassport />,
    [screenStates.CREATE_NEW_PHONE]: <RegistrationPhone />,
    [screenStates.NEW_PHONE_CODE_APPROVE]: <RegistrationCode />,
    [screenStates.CREATE_PASSWORD]: <RegistrationNewPassword />,
  };

  static propTypes = {
    screenState: PropTypes.string.isRequired,
    resetRecoveryAction: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    const { resetRecoveryAction } = this.props;

    resetRecoveryAction();
  }

  render() {
    const { screenState } = this.props;

    return (
      <Fragment>
        {this.recoveryList[screenState]}
      </Fragment>
    );
  }
}


const mapStateToProps = ({ recoveryService }) => ({
  screenState: recoveryService.screenState,
});

const mapDispatchToProps = {
  resetRecoveryAction: resetRecovery,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryForm);
