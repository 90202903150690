import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography, Icons,
} from '@pik/pik-ui';

import AuthForm from '../AuthForm';
import PasswordRecoveryForm from '../RecoveryFormPhone/index';

import { changeScreenState } from '../../../../services/app/actions';

import { screenStates } from '../../../../services/app/constants';

import {
  Container,
  Header,
  Content,
} from './styles';

class Form extends Component {
  contentList = {
    [screenStates.AUTH]: <AuthForm />,
    [screenStates.PASSWORD_RECOVERY]: <PasswordRecoveryForm />,
  };

  static propTypes = {
    changeScreenStateAction: PropTypes.func.isRequired,
    screenState: PropTypes.string.isRequired,
    authType: PropTypes.string,
  };

  static defaultProps = {
    authType: null,
  };

  componentDidMount() {
    const { authType, changeScreenStateAction } = this.props;
    const type = {
      registration: 'REGISTRATION',
      resetpassword: 'PASSWORD_RECOVERY',
    };

    if (!!authType && !!type[authType]) {
      changeScreenStateAction(type[authType]);
    }
  }

  handleChangeScreenState = (event, item) => {
    const { changeScreenStateAction } = this.props;
    changeScreenStateAction(item.value);
  };

  handleClickBackIcon = () => {
    const { changeScreenStateAction } = this.props;
    changeScreenStateAction(screenStates.AUTH);
  };

  renderHeader = () => {
    const { screenState } = this.props;

    if (screenState === screenStates.PASSWORD_RECOVERY) {
      return (
        <Header>
          <button
            onClick={this.handleClickBackIcon}
            type="button"
          >
            <Icons
              type="arrowLeft"
              size="l"
            />
          </button>
          <Typography color="black" type="subTitleOne" weight="medium">
            Восстановить доступ
          </Typography>
          <div />
        </Header>
      );
    }

    return (
      <Header centered>
        <Typography color="black" type="subTitleOne" weight="medium">
          Личный кабинет
        </Typography>
      </Header>
    );
  };

  render() {
    const { screenState } = this.props;

    return (
      <Container>
        {this.renderHeader()}
        <Content>
          {this.contentList[screenState]}
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ appService }) => ({
  screenState: appService.screenState,
});

const mapDispatchToProps = {
  changeScreenStateAction: changeScreenState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
