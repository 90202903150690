import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import {
  Typography,
  Button,
  TextField,
} from '@pik/pik-ui';
import {
  sendCode,
} from '../../../../../../services/recovery/actions';

import {
  Wrapper,
} from './styles';

class PasswordRecoveryPhone extends Component {
  static propTypes = {
    sendCodeAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    phone: null,
    phoneValid: false,
    captcha: null,
  };

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onValidate = (name, isValid) => {
    this.setState({ phoneValid: isValid });
  };

  submitRecovery = () => {
    const { sendCodeAction } = this.props;
    const { phone, phoneValid, captcha } = this.state;

    if (phone && phoneValid && captcha) {
      sendCodeAction({ phone: phone.replace(/[^0-9]+/g, '') }, captcha);
    }
  };

  handleReCaptcha = (token) => {
    const { captcha } = this.state;

    if (!captcha) {
      // TODO: remove
      return this.setState({ captcha: token });
    }

    return null;
  };

  render() {
    const { loading, error } = this.props;
    const { phoneValid, phone } = this.state;

    return (
      <GoogleReCaptchaProvider reCaptchaKey="6LeH-CweAAAAAGsCh0gAcJbFo4Hbaen1F6Y7YvCZ">
        <Typography type="body" color="grayDark">
          Введите номер телефона, мы пришлем вам проверочный код
        </Typography>
        <Wrapper>
          <TextField
            type="newTel"
            value={phone}
            disabled={loading}
            error={error || ''}
            name="phone"
            placeholder="Телефон"
            inputStyle="accent"
            onChange={this.onChange}
            onValidate={this.onValidate}
          />
        </Wrapper>
        <Wrapper>
          <Button
            text="Получить код"
            type="primaryB"
            size="l"
            loading={loading}
            fullWidth
            disabled={!phoneValid}
            onClick={this.submitRecovery}
          />
        </Wrapper>
        <GoogleReCaptcha onVerify={this.handleReCaptcha} />
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = ({ recoveryService }) => ({
  loading: recoveryService.loading,
  error: recoveryService.error,
  screenState: recoveryService.screenState,
});

const mapDispatchToProps = {
  sendCodeAction: sendCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryPhone);
