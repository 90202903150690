export default (callback) => {
  if (!window) {
    return () => {};
  }

  const subscribeEnter = (event) => {
    const { key, keyCode } = event;
    if ((key === 'Enter' || keyCode === 13)) {
      event.preventDefault();
      callback();
    }
  };

  window.addEventListener('keydown', subscribeEnter);
  return () => window.removeEventListener('keydown', subscribeEnter);
};
