import styled from 'styled-components';
import { styleHelpers, mixins } from '@pik/pik-ui';

const {
  colors,
} = styleHelpers;

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const Error = styled.div`
  color: ${colors.stateError};
  box-sizing: border-box;
  padding: 16px 0;
  text-align: center;
`;
