import styled from 'styled-components';
import { styleHelpers, mixins } from '@pik/pik-ui';

const {
  colors,
} = styleHelpers;

export const Title = styled.div`
  text-align: center;
  position: relative;
`;

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const WrapperError = styled.div`
  margin-top: 24px;
  
  .Typography {
    background-color: ${mixins.hex2rgb(colors.stateError, 0.1)}; 
    padding: 6px 16px;
    border-radius: 4px;
    display: block;
    width: auto;
  }
 
`;
