import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography,
  Button,
  TextField,
} from '@pik/pik-ui';
import {
  sendRegistrationCode,
} from '../../../../../../services/recovery/actions';

import {
  Wrapper,
} from './styles';

class RegistrationPhone extends Component {
  static propTypes = {
    sendRegistrationCodeAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    cid: PropTypes.string.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    phone: null,
    phoneValid: false,
  };

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onValidate = (name, isValid) => {
    this.setState({ phoneValid: isValid });
  };

  submitRecovery = () => {
    const { sendRegistrationCodeAction, cid } = this.props;
    const { phone, phoneValid } = this.state;

    if (phone && phoneValid) {
      sendRegistrationCodeAction({ phone: phone.replace(/[^0-9]+/g, ''), cid });
    }
  };

  render() {
    const { loading, error } = this.props;
    const { phoneValid, phone } = this.state;

    return (
      <>
        <Typography type="body" color="grayDark">
          Введите номер мобильного телефона.
        </Typography>
        <Wrapper>
          <TextField
            type="newTel"
            value={phone}
            disabled={loading}
            error={error || ''}
            name="phone"
            placeholder="Телефон"
            inputStyle="accent"
            onChange={this.onChange}
            onValidate={this.onValidate}
          />
        </Wrapper>
        <Wrapper>
          <Button
            text="Получить код"
            type="primaryB"
            size="l"
            loading={loading}
            fullWidth
            disabled={!phoneValid}
            onClick={this.submitRecovery}
          />
        </Wrapper>
      </>
    );
  }
}


const mapStateToProps = ({ recoveryService }) => ({
  loading: recoveryService.loading,
  error: recoveryService.error,
  cid: recoveryService.cid,
});

const mapDispatchToProps = {
  sendRegistrationCodeAction: sendRegistrationCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPhone);
