import styled from 'styled-components';
import { styleHelpers, media } from '@pik/pik-ui';

export const Container = styled.div`
  z-index: 10;
  position: relative;
  max-width: 416px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${styleHelpers.colors.white};
  border: 1px solid ${styleHelpers.colors.secondaryExtraLight};
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 150px auto 0 auto;
  
  @media ${media.max768} {
    margin: 0;
    border-radius: 0;
    height: 100%;
    box-sizing: border-box;  
    max-width: 100%;
  }
`;

export const Content = styled.div`
  padding: 64px 32px 32px;
  width: 100%;
  box-sizing: border-box;
  
  @media ${media.max768} {
    padding: 47px 16px 16px;
  }
`;

export const GroupButtonContainer = styled.div`
  margin-bottom: 24px;
`;

export const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  border-bottom: 1px solid ${styleHelpers.colors.secondaryExtraLight};
  
  .Icons {
    cursor: pointer;
  }
`;
