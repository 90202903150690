import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography,
  Button,
  TextField,
} from '@pik/pik-ui';
import {
  sendRegistrationNewPassword,
} from '../../../../../../services/recovery/actions';

import {
  Wrapper,
} from './styles';

class RegistrationNewPassword extends Component {
  static propTypes = {
    sendRegistrationNewPasswordAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    authKey: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  };

  state = {
    acceptPassword: false,
    passwordOne: '',
    passwordTwo: '',
    error: {
      passwordOne: null,
      passwordTwo: null,
    },
  };

  onChangePassword = (name, value) => {
    const { error } = this.state;
    this.setState({ [name]: value, error: { ...error, [name]: null } }, this.validatePassword);
  };

  validatePassword = () => {
    const { passwordOne, passwordTwo } = this.state;

    if (!!passwordOne && !!passwordTwo && passwordOne.length >= 6 && passwordOne === passwordTwo) {
      this.setState({
        acceptPassword: true,
        error: {
          passwordOne: null,
          passwordTwo: null,
        },
      });
    } else {
      this.setState({ acceptPassword: false });
    }
  };

  checkError = (name, value) => {
    const { passwordOne, error: stateError } = this.state;
    let error = null;

    if (name === 'passwordOne' && value.length < 6) {
      error = 'Пароль слишком короткий';
    }

    if (name === 'passwordTwo' && value !== passwordOne) {
      error = 'Пароли не совпадают';
    }

    this.setState({ error: { ...stateError, [name]: error } });
  };

  submitRecoveryPassword = () => {
    const {
      sendRegistrationNewPasswordAction, authKey, phone,
    } = this.props;
    const { passwordOne, passwordTwo } = this.state;

    if (passwordOne === passwordTwo && authKey) {
      sendRegistrationNewPasswordAction({ authKey, password: passwordOne, phone });
    }
  };

  render() {
    const { loading } = this.props;
    const {
      passwordOne,
      passwordTwo,
      acceptPassword,
      error: {
        passwordOne: errorPasswordOne,
        passwordTwo: errorPasswordTwo,
      },
    } = this.state;

    return (
      <Fragment>
        <Typography type="body" color="grayDark">
          Придумайте новый пароль
        </Typography>
        <Wrapper>
          <TextField
            type="password"
            value={passwordOne}
            disabled={loading}
            error={errorPasswordOne}
            name="passwordOne"
            placeholder="Новый пароль"
            inputStyle="accent"
            onBlur={this.checkError}
            onChange={this.onChangePassword}
            showPassword
          />
        </Wrapper>
        <Wrapper>
          <TextField
            type="password"
            value={passwordTwo}
            disabled={loading}
            error={errorPasswordTwo}
            name="passwordTwo"
            placeholder="Повторите пароль"
            inputStyle="accent"
            onBlur={this.checkError}
            onChange={this.onChangePassword}
            showPassword
          />
        </Wrapper>
        <Wrapper>
          <Button
            text="Сменить пароль"
            type="primaryB"
            size="l"
            loading={loading}
            fullWidth
            disabled={!acceptPassword}
            onClick={this.submitRecoveryPassword}
          />
        </Wrapper>
      </Fragment>
    );
  }
}


const mapStateToProps = ({ recoveryService }) => ({
  authKey: recoveryService.authKey,
  phone: recoveryService.phone,
  loading: recoveryService.loading,
});

const mapDispatchToProps = {
  sendRegistrationNewPasswordAction: sendRegistrationNewPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationNewPassword);
