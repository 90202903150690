import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography,
  Button,
  TextField,
} from '@pik/pik-ui';
import {
  registrationSend,
} from '../../../../../../services/recovery/actions';

import {
  Title,
  Wrapper,
  WrapperError,
} from './styles';

class RegistrationPassport extends Component {
  static propTypes = {
    registrationSendAction: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    error: null,
  };

  state = {
    surname: null,
    passport: null,
    error: {
      surname: null,
      passport: null,
    },
    validate: false,
  };

  onChange = (name, value) => {
    this.setState({ [name]: value }, this.validate);
  };

  submitRegistration = () => {
    const { surname, passport } = this.state;
    const { registrationSendAction } = this.props;

    registrationSendAction({ surname, passport });
  };

  validate = () => {
    const { surname, passport } = this.state;
    const validPassport = !!passport && passport.replace(/\s/g, '').length === 10;
    const validSurname = !!surname && surname.length > 1;
    const resultValid = !!validPassport && !!validSurname;

    this.setState({ validate: resultValid });
  };

  validateError = (name, value) => {
    const { error } = this.state;
    const errorPassport = !value || (!!value && value.replace(/\s/g, '').length < 10) ? 'Некорректный номер паспорта' : null;
    const errorSurname = !value || (!!value && value.length === 1) ? 'Укажите фамилию' : null;
    const resultError = name === 'surname' ? errorSurname : errorPassport;

    this.setState({ error: { ...error, [name]: resultError } });
  };

  renderError = (error) => (
    <WrapperError>
      <Typography type="body" color="stateError">
        { error || 'Пользователь не найден' }
      </Typography>
    </WrapperError>
  );

  render() {
    const {
      surname, passport, validate, error: { surname: surnameError, passport: passportError },
    } = this.state;
    const { loading, error } = this.props;

    return (
      <>
        <Title>
          <Typography type="body" color="grayDark">
            Номер телефона не найден. Попробуйте восстановить доступ по паспортным данным
          </Typography>
          { !!error && this.renderError(error)}
        </Title>
        <Wrapper>
          <TextField
            type="name"
            value={surname}
            disabled={loading}
            error={surnameError}
            size="m"
            name="surname"
            placeholder="Фамилия"
            inputStyle="accent"
            onBlur={this.validateError}
            onChange={this.onChange}
          />
        </Wrapper>
        <Wrapper>
          <TextField
            type="passport"
            value={passport}
            disabled={loading}
            error={passportError}
            size="m"
            name="passport"
            placeholder="Серия и номер паспорта"
            inputStyle="accent"
            onBlur={this.validateError}
            onChange={this.onChange}
          />
        </Wrapper>
        <Wrapper>
          <Button
            text="Далее"
            type="primaryB"
            size="l"
            loading={loading}
            fullWidth
            disabled={!validate}
            onClick={this.submitRegistration}
          />
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ recoveryService }) => ({
  loading: recoveryService.loading,
  error: recoveryService.error,
});

const mapDispatchToProps = {
  registrationSendAction: registrationSend,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPassport);
