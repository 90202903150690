import styled from 'styled-components';
import { styleHelpers, mixins } from '@pik/pik-ui';

export const FormWrapper = styled.form`
  width: 100%;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
`;

export const PasswordRecoveryButton = styled.button`
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: ${styleHelpers.colors.secondaryMedium};
  margin-top: 12px;
  cursor: pointer;
  ${mixins.animation('color')};
  
  &:hover {
    color: ${styleHelpers.colors.grayDark}
  }
`;

export const Error = styled.div`
  width: 100%;
  text-align: center;
  color: ${styleHelpers.colors.stateError};
  font-size: 14px;
  background: rgba(230, 70, 70, 0.1);
  padding: 11px 0;
  border-radius: 4px;
  margin-bottom: 16px;
`;
